import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React, { useState, useEffect } from "react";

import Pages from "./containers/Pages";

import { ThemeProvider } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";

import { AuthProvider } from "./contexts/Auth/AuthContext";
import { HttpProvider } from "./contexts/HTTP/HttpContext";
import { MobileProvider } from "./contexts/Mobile/MobileContext";
import { KioskProvider } from "./contexts/Kiosk/KioskContext";

import { get, post, simpleget, simplepost } from "./utils/api";

import { initializeTranslations } from "./utils/translations";

function App() {
  const [theme, setTheme] = useState(lightTheme);
  const [authenticated, setAuthenticated] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [state, setState] = useState({});
  console.log(
    "DEBUG: App got reloaded. If ran multiple times, maybe this is what caused the issue?"
  );

  // useEffect(() => {
  //   document.body.style.backgroundColor = theme.palette.background.default;
  // });

  //Run Once
  useEffect(() => {
    initializeTranslations();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider value={{ authenticated, setAuthenticated }}>
        <HttpProvider value={{ get, post, simpleget, simplepost }}>
          <MobileProvider value={{ isMobile, setIsMobile }}>
            <KioskProvider value={{ state, setState }}>
              <Pages />
            </KioskProvider>
          </MobileProvider>
        </HttpProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

import { colors } from "@mui/material";
import { createTheme } from "@mui/material/styles";
let env = require("./env.js");

let envColor, primaryColor, secondaryColor;

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = mainColor => augmentColor({ color: { main: mainColor } });

switch (env.REACT_APP_ENV) {
  case "PROD":
    envColor = colors.blue;
    primaryColor = createColor("#000000");
    secondaryColor = colors.pink;
    break;
  case "TEST":
    envColor = colors.deepOrange;
    primaryColor = createColor("#000000");
    secondaryColor = colors.pink;

    break;
  case "LOCAL":
    envColor = colors.blue;
    primaryColor = createColor("#000000");
    secondaryColor = colors.pink;

    break;
  default:
    throw new Error("Error occurred while loading themes (Check ENV)");
}

const PRIMARY_COLOR = primaryColor;
const SECONDARY_COLOR = secondaryColor;

export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: PRIMARY_COLOR,
    secondary: SECONDARY_COLOR,
    envColor,
    errorTextColor: colors.red[500]
  },
  spacing: factor => factor * 8
});

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: PRIMARY_COLOR,
    secondary: SECONDARY_COLOR,
    linkColor: PRIMARY_COLOR[300],
    envColor
  },
  spacing: factor => factor * 8
});

const flexParentPanel = {
  //backgroundColor: "lightgrey",
  marginTop: "10vh",
  marginBottom: "10vh",
  height: "80vh",
  marginLeft: "clamp(20px,5vw, 95px)",
  marginRight: "clamp(20px,5vw, 95px)"
};

/* flexbox centered colmuns */

const flexLeftPanel = {
  backgroundColor: "rgb(39, 167, 55)",
  //backgroundImage: "url(/img/BottlePattern.svg)",  //as per https://gitlab.com/tgayef/lt3-kiosk/-/issues/28
  backgroundSize: "310px 310px",
  backgroundRepeat: "repeat",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  paddingTop: "0px" //Fix for Grid leaving space at the bottom
  //maxWidth: "800px",
  //marginLeft: "auto"
};

const flexLeftPanelMobile = {
  backgroundColor: "rgb(39, 167, 55)",
  //backgroundImage: "url(/img/BottlePattern.svg)",
  backgroundSize: "310px 310px",
  backgroundRepeat: "repeat",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  width: "100vw"
};

const flexRightPanel = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  paddingTop: "0px" //Fix for Grid leaving space at the bottom
  //maxWidth: "800px",
  //marginRight: "auto"
};

const flexRightPanelMobile = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  width: "100vw"
};

const flexLeftPanelContents = {
  width: "100%",
  maxWidth: "800px",
  marginLeft: "auto"
  //backgroundColor: "green"
};

const flexRightPanelContents = {
  width: "100%",
  maxWidth: "800px",
  marginRight: "auto"
};

/* layouts for centered colmuns */

/* layout A: One element Center */
const flexPanelLayoutA_CenterContainer = {
  height: "100%",
  //backgroundColor: "blue",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

/* layout B: 3 element layout */
const flexPanelLayoutB_TopContainer = {
  // height: "15%",
  // //backgroundColor: "blue",
  // display: "flex",
  // flexDirection: "column-reverse",
  // alignItems: "center",
  // //justifyContent: "center",
  // alignContent: "flex-end",
  // alignSelf: "flex-start"

  height: "15%",
  //backgroundColor: "blue",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  //justifyContent: "center",
  //alignContent: "flex-end",
  alignSelf: "flex-start"
};

const flexPanelLayoutB_MidContainer = {
  height: "70%",
  //backgroundColor: "grey",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const flexPanelLayoutB_BotContainer = {
  height: "15%",
  //backgroundColor: "blue",
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "center"
};

/* layout C: 2 element layout */
const flexPanelLayoutC_TopContainer = {
  // height: "15%",
  // //backgroundColor: "blue",
  // display: "flex",
  // flexDirection: "column-reverse",
  // alignItems: "center",
  // //justifyContent: "center"
  // alignContent: "flex-end",
  // alignSelf: "flex-start"

  height: "15%",
  //backgroundColor: "blue",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  //justifyContent: "center"
  alignContent: "flex-end",
  alignSelf: "flex-start"
};

// const flexPanelLayoutC_MidContainer = {
//   height: "85%",
//   //backgroundColor: "blue",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center"
//   // justifyContent: "center"
// };

const flexPanelLayoutC_MidContainer = {
  height: "85%",
  //backgroundColor: "blue",
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "center",
  justifyContent: "space-between"
  // justifyContent: "center"
};

//Full frame spread
const flexPanelLayoutD = {
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignContent: "space-between",
  flexWrap: "wrap"
};

const blackCornedDiv = {
  borderRadius: "25px",
  backgroundColor: "black",
  color: "white",
  maxWidth: "490px",
  width: "41vw",
  //height: "100%",
  padding: "2vh 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const blackCornedDivMarginForSubtitle = {
  //marginTop: "12%"
};

const blackCornedDivReturningContainer = {
  borderRadius: "12px",
  backgroundColor: "black",
  color: "white",
  maxWidth: "280px",
  width: "22vw",
  height: "100%",
  padding: "17px 30px 40px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const leftRightMargins = {
  // marginLeft: "6vw",
  // marginRight: "6vw"
};

const versioning = {
  position: "fixed",
  // left: "3vw",
  // bottom: "3vh",
  left: "1vw",
  bottom: "1vh",
  color: "rgba(255,255,255, 0.8)"
};

const textButtons = {
  margin: "25px 12px 25px 12px",
  // paddingLeft: "2vw",
  // paddingRight: "2vw",
  padding: "1vw 3.5vw",
  height: "6vh",
  //minHeight: "23px",
  textTransform: "capitalize",
  //fontSize: "1.6vw"
  fontSize: "clamp(12px, 1.3vw, 28px)",
  borderRadius: "10px"
};

const blackBodyText = {
  color: "black",
  // fontSize: "clamp(12px, 1.6vw, 28px)"
  fontSize: "clamp(12px, 2vw, 28px)"
  //fontSize: "clamp(12px, 4vh, 28px)",
};

const whiteBodyText = {
  color: "white",
  fontSize: "clamp(15px, 2vw, 30px)",
  //fontSize: "clamp(15px, 4vh, 30px)",
  fontWeight: "400"
};

const whiteSubtitleText = {
  color: "white",
  //fontSize: "clamp(22px, 3vw, 46px)",
  fontSize: "clamp(22px, 3vw, 35px)",
  fontWeight: "400",
  marginBottom: "clamp(0px, 2vw, 27px)"
};

const TitleToLogoAlignment = {
  marginBottom: "clamp(6px, 0.8vw, 62px)"
};

//Depricated
const EnvironmentDisplayTextStyle = {
  color: "yellow",
  fontSize: "clamp(12px, 3vw, 36px)",
  fontWeight: "bold",
  top: "0px",
  left: "0px"
};

const EnvironmentDisplay = {
  position: "relative",
  height: "0px",
  color: "yellow",
  fontSize: "clamp(12px, 3vw, 36px)",
  top: "calc(clamp(-36px,-3vw,-12px) - 1vh)",
  //top: "clamp(-43px,-3.2vw,-19px)", //fontSize -7, -0.2vw, -7
  fontWeight: "bold"
};

const EnvironmentDisplayEmptyPlaceholderStyle = {
  marginTop: "clamp(12px, 4.5vw, 47px)"
};

// Text seems to start offscreen, extra margin on the left is to offset this
const textAlignFix = {
  // marginTop: "2vw",
  // marginLeft: "6vw",
  // marginRight: "2vw"
  marginTop: "clamp(3px, 2vw, 28px)",
  marginLeft: "clamp(3px, 6vw, 1px)",
  marginRight: "clamp(3px, 2vw, 28px)"
};

//IN PROGRESS
const PleaseSignInElement = {
  //height: "0px",
  color: "white",
  // fontSize: "clamp(21px, 3vw, 48px)",
  fontSize: "clamp(22px, 3vw, 35px)",
  fontWeight: "400",
  //marginTop: "1vh",
  textAlign: "left",
  width: "100%",
  //paddingLeft: "clamp(150px, 3vw, 90px)"
  //marginLeft: "clamp(10px, 15vw, 230px)",
  marginBottom: "1vh"
};

const RightPanelTitle = {
  // marginLeft: "clamp(0px, 2vw, 47px)",
  // marginRight: "clamp(0px, 2vw, 47px)",
  fontSize: "clamp(12px, 2.8vw, 43px)",
  textAlign: "center"
};

const RightPanelTitleMobile = {
  fontSize: "clamp(12px, 5.6vh, 43px)",
  textAlign: "center"
};

const LogoTextAlignmentMatcher = {
  //backgroundColor: "green",   //This should match with the Text from the logo
  height: "clamp(0px,3.2vi,48px)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-end",
  alignSelf: "flex-start"
};

const LogoTextAlignmentMatcherMobile = {
  ...LogoTextAlignmentMatcher,
  flexDirection: "column"
};

//This includes the textAlignFix above
const kioskDetails = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  height: "100%",
  width: "100%",
  marginTop: "clamp(0px, 2vh, 27px)"
  // marginLeft: "clamp(3px, 6vw, 80px)",
  // marginRight: "clamp(0px, 2vw, 27px)"
};

const kioskDetailsSpaceBetween = {
  marginTop: "clamp(20px, 2vh, 80px)"
};

const biRowNumberSelect = {
  //marginBottom: "2vw",
  display: "flex",
  justifyContent: "center",
  gap: "2vw"
};

const HelperTextTopMargin = {
  //marginTop: "3em"
};

const HelperTextColor = {
  color: "#696969"
};

const StepperButtonBack = {
  borderRadius: "10px",
  textTransform: "capitalize",
  marginRight: "9px",
  padding: "0.5vw 2vw 0.5vw 1.5vw"
};

const StepperButtonEnd = {
  borderRadius: "10px",
  textTransform: "capitalize",
  padding: "0.5vw 2vw 0.5vw 1.5vw"
};

const GridFullHeightFix = {
  marginTop: "0px"
};

export const kioskstyles = {
  flexParentPanel: flexParentPanel,
  flexLeftPanel: flexLeftPanel,
  flexLeftPanelMobile: flexLeftPanelMobile,
  flexRightPanel: flexRightPanel,
  flexRightPanelMobile: flexRightPanelMobile,
  flexLeftPanelContents: flexLeftPanelContents,
  flexRightPanelContents: flexRightPanelContents,
  flexPanelLayoutA_CenterContainer: flexPanelLayoutA_CenterContainer,
  flexPanelLayoutB_TopContainer: flexPanelLayoutB_TopContainer,
  flexPanelLayoutB_MidContainer: flexPanelLayoutB_MidContainer,
  flexPanelLayoutB_BotContainer: flexPanelLayoutB_BotContainer,
  flexPanelLayoutC_TopContainer: flexPanelLayoutC_TopContainer,
  flexPanelLayoutC_MidContainer: flexPanelLayoutC_MidContainer,
  flexPanelLayoutD: flexPanelLayoutD,
  TitleToLogoAlignment: TitleToLogoAlignment,
  EnvironmentDisplayTextStyle: EnvironmentDisplayTextStyle,
  EnvironmentDisplay: EnvironmentDisplay,
  EnvironmentDisplayEmptyPlaceholderStyle: EnvironmentDisplayEmptyPlaceholderStyle,
  textAlignFix: textAlignFix,
  PleaseSignInElement: PleaseSignInElement,
  kioskDetails: kioskDetails,
  leftRightMargins: leftRightMargins,
  RightPanelTitle: RightPanelTitle,
  RightPanelTitleMobile: RightPanelTitleMobile,
  LogoTextAlignmentMatcher: LogoTextAlignmentMatcher,
  LogoTextAlignmentMatcherMobile: LogoTextAlignmentMatcherMobile,
  versioning: versioning,
  textButtons: textButtons,
  blackBodyText: blackBodyText,
  whiteBodyText: whiteBodyText,
  whiteSubtitleText: whiteSubtitleText,
  kioskDetailsSpaceBetween: kioskDetailsSpaceBetween,
  biRowNumberSelect: biRowNumberSelect,
  HelperTextTopMargin: HelperTextTopMargin,
  HelperTextColor: HelperTextColor,
  StepperButtonBack: StepperButtonBack,
  StepperButtonEnd: StepperButtonEnd,
  GridFullHeightFix: GridFullHeightFix,

  blackCornedDiv: blackCornedDiv,
  blackCornedDivMarginForSubtitle: blackCornedDivMarginForSubtitle,
  blackCornedDivReturningContainer: blackCornedDivReturningContainer
};

// root: {
//   background: "white",

//   "@media (max-width: 1920px)": {
//     background: "red",
//   }
//  }

import React from "react";
import { kioskstyles } from "./../../../../../kioskstyles";

import { getLocale } from "react-i18nify";

import CheckIcon from "@mui/icons-material/Check";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function SelectIndicator(props) {
  const { curentLanguage, language } = props;

  if (curentLanguage !== language) {
    return <></>;
  }

  return (
    <CheckIcon
      style={{
        fontSize: "clamp(12px, 1.3vw, 28px)", //fontSize should match kioskstyles.textButtons
        marginRight: "0.5em"
      }}
    />
  );
}

export default SelectIndicator;

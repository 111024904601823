import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import CircleButton from "./Components/Buttons/CircleButton";
import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";
import KioskDetail from "./Components/Shared/KioskDetail";
import IdleTimerDisplay from "./Components/Shared/IdleTimerDisplay";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import "./../../font.css";

let env = require("./../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { simplepost } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const [numlabels, setNumlabels] = useState("1");

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setlabelsList,
    setAll,
    customerReset
  } = useContext(KioskContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  const numbagpress = async event => {
    let subscriptionID = localStorage.getItem("subscriptionID");
    let kioskID = localStorage.getItem("kioskID");

    const res = await simplepost("/kiosk/submit", {
      subscriptionID: subscriptionID,
      kioskID: kioskID,
      customerName: customerFirstName + " " + customerLastName,
      customerID: customerID,
      numBags: numBags,
      numLabels: event.target.innerText,
      sessionID: sessionID
    });

    Dlog("Number of Labels", res);

    // Show errorscreen if access is denied
    if (res.status == 400) {
      //Kiosk has been disabled
      navigate("/errorscreen");
      return;
    }

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      numBags, //numBags
      event.target.innerText, //numLabels
      res.data.bagtagList, //labelsList
      sessionID //sessionID
    );

    navigate("/printlabels");
  };

  //Return primary or secondary, depending if text matchs numbags
  const ispressed = buttonnumlabels => {
    if (buttonnumlabels === numlabels) {
      return "secondary";
    }

    return "primary";
  };

  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <IdleTimerProvider timeout={30000} onIdle={onIdle}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={kioskstyles.GridFullHeightFix}
      >
        <FlexLeftPanel isNotMobile={isNotMobile}>
          <div style={kioskstyles.flexLeftPanelContents}>
            <div style={kioskstyles.flexParentPanel}>
              <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
                <Logo />
              </div>
              <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
                <div style={kioskstyles.kioskDetails}>
                  <div
                    class="fontSecondaryText"
                    style={kioskstyles.whiteSubtitleText}
                  >
                    <Translate
                      value="application.welcome"
                      name={formatFirstNameLastInitial(
                        customerFirstName,
                        customerLastName
                      )}
                    />
                  </div>
                  <br />
                  <KioskDetail />
                  <br />
                  <div
                    class="fontSecondaryText"
                    style={{
                      ...kioskstyles.whiteBodyText,
                      ...kioskstyles.kioskDetailsSpaceBetween
                    }}
                  >
                    <Translate
                      value="application.kioskdetail.returningnumbags"
                      count={numBags}
                    />
                  </div>
                </div>
              </div>
              <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
                <KioskStepper activeStep={2} backUrl={"/numbags"} />
              </div>
            </div>
          </div>
        </FlexLeftPanel>
        <FlexRightPanel isNotMobile={isNotMobile}>
          <div style={kioskstyles.flexRightPanelContents}>
            <div style={kioskstyles.flexParentPanel}>
              <div style={kioskstyles.flexPanelLayoutD}>
                <SubtitleWrapper isNotMobile={isNotMobile}>
                  <Translate value="application.numberoflabels.selectnumlabels" />
                </SubtitleWrapper>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2vw",
                    width: "100%"
                  }}
                >
                  <div
                    key={"bagsButRow1"}
                    style={kioskstyles.biRowNumberSelect}
                  >
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      0
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      1
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      2
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      3
                    </CircleButton>
                  </div>
                  <div
                    key={"bagsButRow2"}
                    style={kioskstyles.biRowNumberSelect}
                  >
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      4
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      5
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      6
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      7
                    </CircleButton>
                  </div>
                </div>
                <div
                  class="blackBodyText fontNormal"
                  style={{
                    ...kioskstyles.leftRightMargins,
                    ...kioskstyles.blackBodyText,
                    display: "flex",
                    flexDirection: "column-reverse",
                    height: "5em"
                  }}
                >
                  {/* As per https://gitlab.com/tgayef/lt3-kiosk/-/issues/32 */}
                  {/* <div>
                    <span>
                      <b>
                        <Translate value="application.numberoflabels.cantakelabelshome" />
                      </b>
                    </span>{" "}
                    <span style={kioskstyles.HelperTextColor}>
                      <Translate value="application.numberoflabels.uselabelsnexttime" />
                    </span>
                  </div> */}
                  {/* Re-add the margin Bottom style, when we re-add the ubove text */}
                  {/* <div style={{ marginBottom: "1em" }}> */}
                  <div style={{}}>
                    <span>
                      <b>
                        <Translate value="application.numberoflabels.bagsalreadylabelled" />
                      </b>
                    </span>{" "}
                    <span style={kioskstyles.HelperTextColor}>
                      <Translate value="application.numberoflabels.selectzerobags" />
                    </span>
                  </div>
                  <div
                    id="textplaceholder"
                    style={{ height: "2em", marginBottom: "1em" }}
                  ></div>
                </div>
              </div>
              {/* ------------------------------------- */}
            </div>
          </div>
        </FlexRightPanel>
      </Grid>
      <IdleTimerDisplay />
    </IdleTimerProvider>
  );
}

export default NumberOfBags;

import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import CircleButton from "./Components/Buttons/CircleButton";
import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";
import KioskDetail from "./Components/Shared/KioskDetail";
import IdleTimerDisplay from "./Components/Shared/IdleTimerDisplay";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import "./../../font.css";

let env = require("./../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const [numbags, setNumbags] = useState("1");
  //const { customer, setCustomer } = useContext(KioskContext);
  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };
  // const { navigation } = this.props;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      navigate("/");

      setLoading(false);
    }
  });

  const next = () => {
    Dlog("next called");

    Dlog(
      "DEBUG context Dump 3",
      customerFirstName,
      customerLastName,
      customerID,
      numBags,
      numLabels
    );

    //setNumBags(numbags);
    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      numbags, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    Dlog(
      "DEBUG context Dump 3-2",
      customerFirstName,
      customerLastName,
      customerID,
      numBags,
      numLabels
    );

    navigate("/numlabels");
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  const numbagpress = event => {
    setNumbags(event.target.innerText);

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      event.target.innerText, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    navigate("/numlabels");
  };

  //Return primary or secondary, depending if text matchs numbags
  const ispressed = buttonnumbag => {
    if (buttonnumbag === numbags) {
      return "secondary";
    }

    return "primary";
  };

  Dlog("locale", getLocale());

  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <IdleTimerProvider timeout={30000} onIdle={onIdle}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={kioskstyles.GridFullHeightFix}
      >
        <Grid item xs={12} sm={6} style={KioskStylesReact().flexLeftPanel}>
          <div style={kioskstyles.flexLeftPanelContents}>
            <div style={kioskstyles.flexParentPanel}>
              <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
                <Logo />
              </div>
              <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
                <div style={kioskstyles.kioskDetails}>
                  <div
                    class="fontSecondaryText"
                    style={kioskstyles.whiteSubtitleText}
                  >
                    <Translate
                      value="application.welcome"
                      name={formatFirstNameLastInitial(
                        customerFirstName,
                        customerLastName
                      )}
                    />
                  </div>
                  <br />
                  <KioskDetail />
                </div>
              </div>
              <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
                <KioskStepper activeStep={1} backUrl={"/phonenumber"} />
              </div>
            </div>
          </div>
        </Grid>

        <FlexRightPanel isNotMobile={isNotMobile}>
          <div style={kioskstyles.flexRightPanelContents}>
            <div style={kioskstyles.flexParentPanel}>

              <div style={kioskstyles.flexPanelLayoutD}>
                <SubtitleWrapper isNotMobile={isNotMobile}>
                  <Translate value="application.numberofbags.selectnumbags" />
                </SubtitleWrapper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2vw",
                    width: "100%"
                  }}
                >
                  <div
                    key={"bagsButRow1"}
                    style={kioskstyles.biRowNumberSelect}
                  >
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      0
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      1
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      2
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      3
                    </CircleButton>
                  </div>
                  <div
                    key={"bagsButRow2"}
                    style={kioskstyles.biRowNumberSelect}
                  >
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      4
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      5
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      6
                    </CircleButton>
                    <CircleButton PercentSize="10%" onClick={numbagpress}>
                      7
                    </CircleButton>
                  </div>
                </div>
                <div
                  class="blackBodyText fontNormal"
                  style={{
                    ...kioskstyles.leftRightMargins,
                    ...kioskstyles.blackBodyText,
                    display: "flex",
                    flexDirection: "column-reverse",
                    height: "5em"
                  }}
                >
                  <div>
                    <span>
                      <b>
                        <Translate value="application.numberofbags.needonlybags" />
                      </b>
                    </span>{" "}
                    <span style={kioskstyles.HelperTextColor}>
                      <Translate value="application.numberofbags.selectzerobags" />
                    </span>
                  </div>
                  <div style={{ marginBottom: "1em" }}>
                    <span>
                      <b>
                        <Translate value="application.numberofbags.havemorebags" />
                      </b>
                    </span>{" "}
                    <span style={kioskstyles.HelperTextColor}>
                      <Translate value="application.numberofbags.multipleinteractions" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FlexRightPanel>
      </Grid>
      <IdleTimerDisplay />
    </IdleTimerProvider>
  );
}

export default NumberOfBags;

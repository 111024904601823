import React, { useContext, useState, useRef, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";
import { useReactToPrint } from "react-to-print";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import { LabelsToPrint } from "./Components/LabelsToPrint";

import { Capacitor } from "@capacitor/core";
import EpsonPrinter from "./../../plugin/EsponPrinter";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";

import CircleButton from "./Components/Buttons/CircleButton";
import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";
import KioskDetail from "./Components/Shared/KioskDetail";
import IdleTimerDisplay from "./Components/Shared/IdleTimerDisplay";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

let env = require("./../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const {
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    labelsList,
    setAll,
    customerReset
  } = useContext(KioskContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  useEffect(() => {
    next();
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const next = async () => {
    Dlog("next called");

    Dlog(
      "DEBUG context Dump 5",
      customerFirstName,
      customerLastName,
      customerID,
      numBags,
      numLabels
    );

    const androidEsponPrinter = Capacitor.isPluginAvailable("EpsonPrinter");
    if (androidEsponPrinter) {
      //let value = EpsonPrinter.echo({ value: "Hello World!" });
      try {
        let value = await EpsonPrinter.PrintWithSelectedPrinter({
          bagtags: labelsList
        });
        Dlog("Response from native:", value);
      } catch (e) {
        console.log("CATCHERROR: " + e.toString());
      }
    } else {
      console.log("EpsonPrinter plugin unvavailable. Probably not android");
      handlePrint();
    }

    navigate("/thanksyou");
  };

  Dlog("PrintLabel", customerFirstName, customerLastName, labelsList);

  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <IdleTimerProvider timeout={30000} onIdle={onIdle}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={kioskstyles.GridFullHeightFix}
      >
        <FlexLeftPanel isNotMobile={isNotMobile}>
          <div style={kioskstyles.flexLeftPanelContents}>
            <div style={kioskstyles.flexParentPanel}>
              <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
                <Logo />
              </div>
              <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
                <div style={kioskstyles.kioskDetails}>
                  <div
                    class="fontSecondaryText"
                    style={kioskstyles.whiteSubtitleText}
                  >
                    <Translate
                      value="application.welcome"
                      name={formatFirstNameLastInitial(
                        customerFirstName,
                        customerLastName
                      )}
                    />
                  </div>
                  <br />
                  <KioskDetail />
                  <br />
                  <div
                    class="fontSecondaryText"
                    style={{
                      ...kioskstyles.whiteBodyText,
                      ...kioskstyles.kioskDetailsSpaceBetween
                    }}
                  >
                    <Translate
                      value="application.kioskdetail.returningnumbags"
                      count={numBags}
                    />
                  </div>
                  <br />
                  <div
                    class="fontSecondaryText"
                    style={{
                      ...kioskstyles.whiteBodyText,
                      ...kioskstyles.kioskDetailsSpaceBetween
                    }}
                  >
                    <Translate
                      value="application.kioskdetail.returningnumlabels"
                      count={numLabels}
                    />
                  </div>
                </div>
              </div>
              <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
                <KioskStepper activeStep={2} backUrl={"/numlabels"} />
              </div>
            </div>
          </div>
        </FlexLeftPanel>
        <FlexRightPanel isNotMobile={isNotMobile}>
          <div style={kioskstyles.flexRightPanelContents}>
            <div style={kioskstyles.flexParentPanel}>
              <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
                <SubtitleWrapper isNotMobile={isNotMobile}>
                  <Translate value="application.printing.readytoprint" />
                </SubtitleWrapper>
              </div>
              <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
                <PrintIcon style={{ fontSize: "clamp(70px, 25vw, 300px)" }} />
                <LabelsToPrint
                  ref={componentRef}
                  customerName={formatFirstNameLastInitial(
                    customerFirstName,
                    customerLastName
                  )}
                  labelsList={labelsList}
                />
              </div>
              <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
                {/* <Button
                  variant="contained"
                  disabled={loading}
                  onClick={next}
                  style={kioskstyles.textButtons}
                >
                  <span class="fontSecondaryText">
                    <Translate value="application.printing.taptoprint" />
                  </span>
                </Button> */}
              </div>
            </div>
          </div>
        </FlexRightPanel>
      </Grid>
      <IdleTimerDisplay />
    </IdleTimerProvider>
  );
}

export default NumberOfBags;

import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
import QRCode from "react-qr-code";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import CircleButton from "./Components/Buttons/CircleButton";
import Logo from "./Components/Logo";
import LanguageButtons from "./Components/Shared/LanguageButtons";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

let env = require("./../../env");

function displayError(errorCode) {
  switch (errorCode) {
    case 400:
      return translate("application.kioskdetail.errors.unabletoprocesskey");
    case 401:
      return translate("application.errors.invalidcredentials");
    case 403:
      return translate("application.kioskdetail.errors.keyerror");
    case 404:
      return translate("application.errors.invalidcredentials");
    case 503:
      return translate("application.errors.serverunavailable");
    default:
      return translate("application.errors.unknownerror");
  }
}

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { simplepost } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [kioskkey, setKioskkey] = useState("");

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    customerReset
  } = useContext(KioskContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  // const { navigation } = this.props;

  //Check and generate a UniqueKioskID
  useEffect(() => {
    let kioskID = localStorage.getItem("kioskID");
    Dlog("kioskID from localStorage", kioskID);

    let newKioskID = null;

    if (kioskID == null) {
      //Generate a new one!
      if (crypto.randomUUID != null) {
        newKioskID = crypto.randomUUID();
        localStorage.setItem("kioskID", newKioskID);
      } else {
        // in the case of http, randomUUID is not available; This case should be used on the servers, as the servers should redirect to https.
        console.log(
          "[WARNING] crypto.randomUUID doesn't exist, HTTPS is probably not enabled. Using fallback."
        );
        newKioskID = mockUniqueID(32);
        localStorage.setItem("kioskID", newKioskID);
      }
    }

    //if Kiosk Key available, attempt auto login
    let subscriptionID = localStorage.getItem("subscriptionID");
    if (subscriptionID !== null) {
      //auto login
      setKioskkey(subscriptionID);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      navigate("/");

      setLoading(false);
    }
  });

  const next = async () => {
    Dlog("next called");

    //Get KioskID
    let kioskID = localStorage.getItem("kioskID");
    Dlog("kioskID from localStorage", kioskID);

    //TODO: Attempt Kiosk Login
    const res = await simplepost("/kiosk/login", {
      subscriptionID: kioskkey,
      kioskID: kioskID
    });

    Dlog("Config res status", res.status, res.status >= 400);

    if (res.status >= 400) {
      setErrorMessage(displayError(res.status));
    } else {
      //console.log("AConfiguration res.data", res.data);
      localStorage.setItem("subscriptionID", kioskkey);
      localStorage.setItem("LocationAddress", res.data.locationAddress);
      localStorage.setItem("LocationName", res.data.locationName);
      setErrorMessage("");
      navigate("/language");
    }

    setLoading(false);
  };

  // const tempDebug = async () => {
  //   navigate("/debug");
  // };

  const navigatePrinterDebug = async () => {
    navigate("/printerdebug");
  };

  const isNotMobile = useMediaQuery("(min-width:600px)");

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={kioskstyles.GridFullHeightFix}
    >
      <FlexLeftPanel isNotMobile={isNotMobile}>
        <div style={kioskstyles.flexLeftPanelContents}>
          <div style={kioskstyles.flexParentPanel}>
            <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
              <Logo />
            </div>
            <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
              <div>
              </div>
            </div>
            <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
              <LanguageButtons />
            </div>
          </div>
        </div>
      </FlexLeftPanel>
      <FlexRightPanel isNotMobile={isNotMobile}>
        <div style={kioskstyles.flexRightPanelContents}>
          <div style={kioskstyles.flexParentPanel}>
            <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
              <SubtitleWrapper isNotMobile={isNotMobile}>
                <Translate value="application.configuration.configuration" />
              </SubtitleWrapper>
            </div>
            <div style={kioskstyles.flexPanelLayoutB_MidContainer}>
              <Typography
                variant="h5"
                style={{ fontSize: "clamp(0.6rem, 2vw, 1.6rem)" }}
              >
                <Translate value="application.configuration.enterkey" />
              </Typography>
              <TextField
                id="KioskKey"
                label=""
                value={kioskkey}
                onChange={event => {
                  setKioskkey(event.target.value);
                }}
              />
              <Typography
                variant="body2"
                class="fontSecondaryText"
                style={{
                  color: "red",
                  marginTop: theme.spacing(0.5),
                  fontSize: "clamp(12px, 1.3vw, 20px)",
                  textAlign: "center"
                }}
              >
                {errorMessage}
              </Typography>
            </div>
            <div style={kioskstyles.flexPanelLayoutB_BotContainer}>
              {/* DEBUG BUTTONS */}
              {/* <Button
                variant="contained"
                disabled={loading}
                onClick={navigatePrinterDebug}
                style={kioskstyles.textButtons}
              >
                <span class="fontSecondaryText">
                  <Translate value="application.configuration.printerdebug" />
                </span>
              </Button> */}
              {/* END DEBUG BUTTONS */}

              <Button
                variant="contained"
                disabled={loading}
                onClick={next}
                style={{ ...kioskstyles.textButtons, marginBottom: "0px" }}
              >
                <span class="fontSecondaryText">
                  <Translate value="application.next" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </FlexRightPanel>
    </Grid>
  );
}

function mockUniqueID(length) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export default NumberOfBags;

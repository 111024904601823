import React, { useEffect, useState } from "react";
import "./../../../../font.css";
import { kioskstyles } from "./../../../../kioskstyles";
import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import SelectIndicator from "./LanguageButtons/SelectIndicator";

import {
  setTranslations,
  Translate,
  setLocale,
  getLocale
} from "react-i18nify";
let env = require("./../../../../env");

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function LanguageButtons(props) {
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(getLocale()); //Initialize the language
  }, []);

  const setFrench = event => {
    let locale = getLocale();

    if (props.AdditionalOnClick && locale == "fr") {
      props.AdditionalOnClick();
    } else {
      setLocale("fr");
      setLanguage("fr"); //To make sure the indicator refreshs
    }
  };

  const setEnglish = event => {
    let locale = getLocale();

    if (props.AdditionalOnClick && locale == "en") {
      props.AdditionalOnClick();
    } else {
      setLocale("en");
      setLanguage("en"); //To make sure the indicator refreshs
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <Button
        fullWidth
        variant="contained"
        onClick={setFrench}
        style={{
          ...kioskstyles.textButtons,
          marginBottom: "0px",
          marginTop: "0px"
        }}
      >
        <SelectIndicator curentLanguage={language} language={"fr"} />
        <span class="fontSecondaryText">
          <Translate value="application.languageselect.french" />
        </span>
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={setEnglish}
        style={{
          ...kioskstyles.textButtons,
          marginBottom: "0px",
          marginTop: "0px"
        }}
      >
        <SelectIndicator curentLanguage={language} language={"en"} />
        <span class="fontSecondaryText">
          <Translate value="application.languageselect.english" />
        </span>
      </Button>
    </div>
  );
}

export default LanguageButtons;
